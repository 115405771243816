import { MutableRefObject, forwardRef, isValidElement, useRef } from 'react';
import compact from 'lodash/compact';

import theme from 'themes';
import { ColorKey } from 'components/utils/styles/types';

import * as styled from './styles/button';
import { Icon } from './icon';
import {
  BUTTON_SIZES as SIZES,
  BUTTON_HEIGHTS as HEIGHTS,
} from './constants/buttons';
import type {
  ButtonType,
  ButtonSize,
  ButtonColor,
  ButtonHTMLType,
  Color,
} from './types';

type ButtonProps = {
  htmlType?: ButtonHTMLType;
  color?: ButtonColor | ColorKey;
  size?: ButtonSize;
  type?: ButtonType;
  loading?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  fullWidth?: boolean;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
  to?: string | object | null;
  dataManual?: string;
  dataTest?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  spinnerColor?: Color;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      htmlType = 'button',
      type,
      className,
      loading = false,
      disabled = false,
      rounded = false,
      fullWidth = false,
      children = '',
      to,
      onClick,
      dataManual,
      dataTest,
      color = 'primary',
      size = SIZES.normal,
      spinnerColor,
      ...rest
    }: ButtonProps,
    ref
  ) => {
    const btnRef = ref || useRef<HTMLButtonElement>(null);
    const rippleRef = useRef<HTMLSpanElement>(null);
    const isChildrenArray = Array.isArray(children);

    const hasMultipleChildren = isChildrenArray && compact(children).length > 1;
    const hasIconLeft =
      isChildrenArray &&
      isValidElement(children?.[0]) &&
      children?.[0]?.type === Icon &&
      hasMultipleChildren;
    const hasIconRight =
      isChildrenArray &&
      isValidElement(children.at(-1)) &&
      children.at(-1).type === Icon &&
      hasMultipleChildren;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);
      const ripple = rippleRef.current;
      const button = (btnRef as MutableRefObject<HTMLButtonElement>).current;
      if (ripple && button) {
        ripple.classList.remove('animate');
        ripple.style.height = `${Math.max(
          button.offsetWidth,
          button.offsetHeight
        )}px`;
        ripple.style.width = ripple.style.height;

        const offsetX = e.clientX - button.getBoundingClientRect().left;
        const offsetY = e.clientY - button.getBoundingClientRect().top;
        ripple.style.left = `${offsetX - ripple.offsetWidth / 2}px`;
        ripple.style.top = `${offsetY - ripple.offsetHeight / 2}px`;
        ripple.classList.add('animate');
      }
    };

    const ButtonComponent = to ? styled.ButtonLink : styled.Button;

    return (
      <ButtonComponent
        type={htmlType}
        styleType={type}
        className={className}
        isLoading={loading}
        spinnerColor={spinnerColor}
        disabled={disabled}
        rounded={rounded}
        fullWidth={fullWidth}
        hasIconLeft={hasIconLeft}
        hasIconRight={hasIconRight}
        theme={theme}
        data-manual={dataManual}
        data-test={dataTest}
        onClick={handleClick}
        color={color}
        size={size}
        {...(to && { to })}
        {...rest}
        ref={btnRef}
      >
        {children}
        <styled.ButtonSpacingWrapper>
          <styled.ButtonRipple ref={rippleRef} />
        </styled.ButtonSpacingWrapper>
      </ButtonComponent>
    );
  }
);

export const BUTTON_SIZES = SIZES;
export const BUTTON_HEIGHTS = HEIGHTS;
export const { buttonSizeMixin } = styled;
