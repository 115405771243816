import { get } from 'lodash';

import { COMPANY_PROFILE_UPDATE_SUCCESS } from 'containers/CompanyProfile/constants';
import { COMPANY_UPDATE_SUCCESS } from 'containers/Settings/Company/constants';
import { ONBOARDING_SUCCESS } from 'containers/Onboarding/constants';
import { SUBSCRIPTION_CHANGE_SUCCESS } from 'containers/SubscriptionPlan/constants';

const initialState = {
  model: null,
  channel: null,
};

export function companyReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'COMPANY_CHANNEL_JOINED':
      return {
        ...state,
        model: action.model,
        channel: action.channel,
      };

    case 'COMPANY_CHANNEL_LEFT':
      return initialState;

    case COMPANY_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        model: {
          ...state.model,
          profile_completion: action.payload.profile_completion,
          contact_person_id: get(action.payload, 'contact_person.id'),
        },
      };

    case SUBSCRIPTION_CHANGE_SUCCESS:
      return {
        ...state,
        model: {
          ...state.model,
          chargebee_id: action.payload.chargebee_id,
          subscription_plan: action.payload.subscription_plan,
          subscription_period: action.payload.subscription_period,
          subscription_reset_at: action.payload.subscription_reset_at,
        },
      };

    case COMPANY_UPDATE_SUCCESS: {
      return {
        ...state,
        model: {
          ...state.model,
          currency: action.payload.settings.currency,
        },
      };
    }

    case ONBOARDING_SUCCESS:
      return {
        ...state,
        model: {
          ...state.model,
          ...action.payload.company,
        },
      };

    default:
      return state;
  }
}
