import { defineMessages } from 'react-intl';

import theme from 'themes';

const i18n = defineMessages({
  title: {
    id: 'app.trialExpired.title',
    defaultMessage: 'Your trial period has expired!',
  },
  subtitle: {
    id: 'app.trialExpired.subtitle',
    defaultMessage: `Hi {userName},\nIt appears that your account is no longer active. But don't worry. Subscribe right away to resume the ${theme.platformName} experience.`,
  },
  check1: {
    id: 'app.trialExpired.check1',
    defaultMessage:
      'Your current jobs and data remain the same in your account.',
  },
  check2: {
    id: 'app.trialExpired.check2',
    defaultMessage:
      'Once you subscribed, your account will be instantly unlocked and upgraded.',
  },
  button: {
    id: 'app.trialExpired.button',
    defaultMessage: 'Talk to Sales',
  },
});

export default i18n;
