import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isObject } from 'lodash';

import { Tooltip } from 'components/overlay/Tooltip';

import * as styled from './styles';
import i18n from './utils/i18n';

export const InputColor = (props) => {
  const { className, value, disabled, tooltip, onChange } = props;
  const input = (
    <styled.InputColor className={className} disabled={disabled}>
      <styled.InputWrapper style={{ backgroundColor: value }}>
        <styled.Input
          type="color"
          value={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
        />
      </styled.InputWrapper>
      <styled.ColorCode>{value}</styled.ColorCode>
    </styled.InputColor>
  );

  if (!tooltip) {
    return input;
  }

  return (
    <Tooltip
      color="dark"
      position="S"
      content={
        <FormattedMessage {...(isObject(tooltip) ? tooltip : i18n.tooltip)} />
      }
    >
      {input}
    </Tooltip>
  );
};

InputColor.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onChange: PropTypes.func,
};
