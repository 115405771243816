import { get, map, union, isEmpty, filter, flatten, uniqBy } from 'lodash';
import type { FileRejection } from 'react-dropzone';

import i18nForm from 'scenes/utils/form/i18n';
import { openFlashMessage } from 'components/utils/flashMessages';

export type FileUpload = {
  file: File;
  dimensions?: { width: number; height: number };
  status: 'accepted' | 'rejected';
} & File &
  FileInfo;

type Restrictions = {
  maxFileSize: number;
  acceptedFormats: string;
  acceptedDimensions: {
    min: { width: number; height: number };
    max: { width: number; height: number };
  };
  maxFiles: number;
  custom: I18nMessage;
};

type ValidationMessage = {
  detail: I18nMessage;
  values?: object;
};

type RejectedFileInfo = {
  file: FileRejection & RejectedFile;
} & FileRejection;

type RejectedFile = {
  status: 'rejected';
} & FileInfo;

type FileInfo = {
  filename: string;
  ext: string;
  error: string;
  restrictions: Restrictions;
};

/**
 * @function acceptedOrRejected
 * @param {array} acceptedFiles
 * @param {array} rejectedFiles
 * @param {object} restrictions
 * @param {number} restrictions.maxFileSize
 * @param {string} restrictions.acceptedFormats
 * @return {array}
 */
/* eslint no-param-reassign:0 */
export function acceptedOrRejected(
  acceptedFiles: FileUpload[],
  rejectedFiles: RejectedFileInfo[],
  restrictions: Restrictions
) {
  const accepted = map(acceptedFiles, (file) => {
    file.filename = file.name;

    return { status: 'accepted', file, ext: file.name.split('.').pop() };
  });

  const rejected = map(rejectedFiles, ({ file, errors }) => {
    file.filename = file.name;

    return {
      file,
      status: 'rejected',
      error: get(errors, '0.code'),
      ext: file.name.split('.').pop(),
      restrictions,
    };
  });

  return union(accepted, rejected);
}

/**
 * @function rejectedFilesMessages
 * @param {array} files
 * @return {array}
 */
export function rejectedFilesMessages(
  files: FileUpload[]
): ValidationMessage[] {
  return uniqBy(
    flatten(
      map(files, (file) => {
        const {
          restrictions: { custom, maxFileSize, maxFiles },
          error,
        } = file;

        switch (error) {
          case 'file-too-large':
            return [
              {
                detail: custom || i18nForm.uploadDocSizeError,
                values: {
                  max: `${maxFileSize / (1024 * 1024)}MB`,
                  total: files.length,
                },
              },
            ];

          case 'file-invalid-type':
            return [
              {
                detail: custom || i18nForm.uploadDocExtError,
                values: { ext: file.ext.toUpperCase(), total: files.length },
              },
            ];

          case 'file-dimensions-too-small':
            return [
              {
                detail: i18nForm.uploadDimensionsMinError,
              },
            ];

          case 'file-dimensions-too-high':
            return [
              {
                detail: i18nForm.uploadDimensionsMaxError,
              },
            ];

          case 'too-many-files':
            return [
              {
                detail: i18nForm.uploadFilesLimitError,
                values: { max: maxFiles },
              },
            ];

          default:
            return [];
        }
      })
    ),
    'detail.id'
  );
}

/**
 * @function returnOrNotifyUploadedFile
 * @param {array} files
 * @return {object | null}
 */
export function returnOrNotifyUploadedFile(files: FileUpload[]) {
  if (isEmpty(files)) {
    return null;
  }

  const selected = filter(files, { status: 'accepted' })[0];
  if (selected) {
    return selected.file;
  }

  openFlashMessage(
    {
      context: rejectedFilesMessages(files),
      status: 'error',
    },
    { highlight: 'bold' }
  );
  return null;
}

/**
 * @function returnOrNotifyUploadedFiles
 * @param {array} files
 * @return {array | null}
 */
export function returnOrNotifyUploadedFiles(files: FileUpload[]) {
  if (isEmpty(files)) {
    return null;
  }

  const rejected = filter(files, { status: 'rejected' });
  if (rejected.length > 0) {
    openFlashMessage(
      {
        context: rejectedFilesMessages(rejected),
        status: 'error',
      },
      { highlight: 'bold' }
    );
  }

  const selected = filter(files, { status: 'accepted' });
  if (selected.length > 0) {
    return selected;
  }

  return null;
}

/* eslint func-names: 0 */
export function filesGetter(event: DragEvent, restrictions: Restrictions) {
  const files = event.dataTransfer
    ? event.dataTransfer.files
    : (event.target as HTMLInputElement).files;

  if (restrictions.acceptedDimensions) {
    const promises: Promise<File>[] = map(
      files,
      (file) =>
        new Promise((resolve): void => {
          const preview = URL.createObjectURL(file);
          const img = new Image();
          img.onload = function () {
            Object.defineProperty(file, 'dimensions', {
              value: { width: img.width, height: img.height },
            });
            resolve(file);
            URL.revokeObjectURL(preview);
          };
          img.onerror = function () {
            resolve(file);
            URL.revokeObjectURL(preview);
          };
          img.src = preview;
        })
    );

    return Promise.all(promises);
  }

  const promises: Promise<File>[] = map(
    files,
    (file) => new Promise((resolve) => resolve(file))
  );
  return Promise.all(promises);
}

export function customValidator(file: FileUpload, restrictions: Restrictions) {
  const min = get(restrictions, 'acceptedDimensions.min');
  const max = get(restrictions, 'acceptedDimensions.max');
  const dim = file.dimensions;

  if (min && dim && (dim.width < min.width || dim.height < min.height)) {
    return {
      code: 'file-dimensions-too-small',
      message: 'Image dimensions too small',
    };
  }
  if (max && dim && (dim.width > max.width || dim.height > max.height)) {
    return {
      code: 'file-dimensions-too-high',
      message: 'Image dimensions too high',
    };
  }
  return null;
}
