import { isEmpty, isObject, identity } from 'lodash';

import { TextSmartLink } from 'components/navigation/TextSmartLink';

import * as styled from './styles';

type TagProps = {
  className?: string;
  tags: TagObject[];
  size?: 'small' | 'normal';
  onAdd?: (tag: TagObject) => void;
  linkFn?: (tag: TagObject) => string;
  formatFn?: (tag: TagObject) => React.ReactNode;
  onRemove?: (index: number) => void;
};

type TagObject = {
  name: string;
};

/* eslint-disable react/no-array-index-key */
export const Tags = (props: TagProps) => {
  const {
    className,
    tags = [],
    size = 'normal',
    onAdd,
    linkFn,
    formatFn = identity,
    onRemove,
  } = props;

  if (isEmpty(tags)) {
    return null;
  }

  return (
    <styled.TagsList
      className={className}
      data-resume="components.visual.tagsList"
    >
      {tags.map((tag, index) => (
        <styled.Tag
          data-resume="component.visual.tags.tag"
          key={`tag-${index}-${isObject(tag) ? tag.name : tag}`}
          size={size}
          onClick={onAdd ? () => onAdd(tag) : undefined}
        >
          {onAdd && <styled.AddIcon icon="Plus" color="text" />}
          {linkFn ? (
            <TextSmartLink to={linkFn(tag)} target="_blank">
              {formatFn(tag)}
            </TextSmartLink>
          ) : (
            formatFn(tag)
          )}
          {onRemove && (
            <styled.RemoveIcon
              icon="X"
              color="tealDark"
              onClick={() => onRemove(index)}
            />
          )}
        </styled.Tag>
      ))}
    </styled.TagsList>
  );
};
