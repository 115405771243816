import { createContext, Component, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { some, uniq, without, isEmpty } from 'lodash';

import { SegmentAPI } from 'components/scripts/segment';
import { shortUuidRegex } from 'utils/regexes';

const ScrollActiveOnPaths = ['/settings/integrations/\\w*', '/settings/\\w*'];

const DisableActiveOnPaths = [
  `^/shared-lists/${shortUuidRegex}/(candidates|messages)/${shortUuidRegex}`,
];

const RETRY_TIMES = 10;
const RETRY_TIMEOUT = 100;

const LAUNCHER_SELECTOR =
  '.intercom-app, .intercom-launcher, .intercom-launcher-frame, .intercom-launcher-badge-frame';

export const IntercomAPI = (...args) => {
  if (window.Intercom) {
    window.Intercom.apply(null, args);
  }
};

export const IntercomLauncherToggle = (hide) => {
  const retry = (times) => {
    const launchers = document.querySelectorAll(LAUNCHER_SELECTOR);

    if (!isEmpty(launchers)) {
      if (hide) {
        launchers.forEach((launcher) => {
          launcher.style.setProperty('position', 'absolute');
          launcher.style.setProperty('z-index', '-1');
          launcher.style.setProperty('opacity', '0');
        });
      } else {
        launchers.forEach((launcher) => {
          launcher.style.removeProperty('position');
          launcher.style.removeProperty('z-index');
          launcher.style.removeProperty('opacity');
        });
      }
    } else if (times > 0) {
      setTimeout(() => retry(times - 1), RETRY_TIMEOUT);
    }
  };

  retry(RETRY_TIMES);
};

export const Context = createContext({});

export const Provider = ({ children }) => {
  const [hidden, setHidden] = useState([]);
  const handleChangeHidden = (value, scope) => {
    const newHidden = value
      ? uniq(hidden.concat(scope))
      : without(hidden, scope);

    if (newHidden.length !== hidden.length) {
      IntercomLauncherToggle(!isEmpty(newHidden));
      setHidden(newHidden);
    }
  };

  return (
    <Context.Provider
      value={{
        hidden: !isEmpty(hidden),
        setHidden: handleChangeHidden,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

class IntercomComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      track: false,
      isBottom: false,
    };
  }

  componentDidMount() {
    const { history, location } = this.props;
    SegmentAPI('page');

    this.unlisten = history.listen((newLocation) => {
      setTimeout(() => {
        this.setupTracking(newLocation);
        this.setupVisibility(newLocation);
      }, 100);
    });

    this.setupTracking(location);
    this.setupVisibility(location);
  }

  componentWillUnmount() {
    this.unlisten();
    IntercomAPI('shutdown');
  }

  setupTracking(location) {
    const { track } = this.state;
    const shouldTrack = this.shouldTrackScroll(location);

    if (shouldTrack && !track) {
      this.setState({ track: true, isBottom: false }, () => {
        document.addEventListener('scroll', this.trackScrolling);
      });
    } else if (!shouldTrack && track) {
      this.setState({ track: false, isBottom: false }, () => {
        this.moveIcon('2rem');
        document.removeEventListener('scroll', this.trackScrolling);
      });
    }
  }

  setupVisibility(location) {
    const { setHidden } = this.context;

    setHidden(this.shouldHide(location), 'self');
  }

  /* eslint no-param-reassign: 0 */
  moveIcon = (bottomPosition) => {
    const elements = document.querySelectorAll(LAUNCHER_SELECTOR);
    elements.forEach((el) => {
      if (el.style) {
        el.style.bottom = bottomPosition;
      }
    });
  };

  trackScrolling = () => {
    const { appContainer, scrollUp } = this.props;
    const { isBottom } = this.state;

    if (!appContainer) {
      return;
    }

    if (this.isBottom(appContainer)) {
      if (!isBottom) {
        this.moveIcon('10rem');
        this.setState({ isBottom: true });
      }
    } else if (isBottom) {
      if (!scrollUp) {
        this.moveIcon('2rem');
      }
      this.setState({ isBottom: false });
    }
  };

  shouldTrackScroll(location) {
    const { pathname } = location;

    return some(ScrollActiveOnPaths, (re) =>
      new RegExp(re, 'i').test(pathname)
    );
  }

  shouldHide(location) {
    const { pathname } = location;

    return some(DisableActiveOnPaths, (re) =>
      new RegExp(re, 'i').test(pathname)
    );
  }

  isBottom(el) {
    return (
      parseInt(el.getBoundingClientRect().bottom, 10) <= window.innerHeight
    );
  }

  render() {
    return null;
  }
}

IntercomComponent.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  appContainer: PropTypes.object,
  scrollUp: PropTypes.bool,
};

IntercomComponent.contextType = Context;

export const Intercom = withRouter(IntercomComponent);
