import PropTypes from 'prop-types';
import Composer from 'react-composer';
import { injectIntl, FormattedMessage } from 'react-intl';
import { map, isEmpty } from 'lodash';

import { companyID } from 'utils/authorization/utils/auth';
import NotesProvider from 'containers/CompanyNotes/Provider';
import DocumentsProvider from 'containers/CompanyDocuments/Provider';

import * as styled from '../styles';
import * as styledUI from '../styles/section';
import i18n from '../utils/i18n';
import {
  shouldShowPerformance,
  isEndClient as isEndClientFn,
  isAffiliated as isAffiliatedFn,
} from '../utils/helpers';
import {
  performanceTo,
  performanceFrom,
  performanceOverall,
} from '../utils/performance';

import { About } from '../ui/about';
import { Performance } from '../ui/performance';
import { AssocList } from '../ui/assocList';
import { Notes } from '../ui/notes';
import { Documents } from '../ui/documents';
import { VerificationDocuments } from '../ui/verificationDocuments';

import { PremiumFeatureScene } from '../../PremiumFeature';
import { BlockWidget } from '../ui/blockWidget';

function AboutTabComponent(props) {
  const {
    intl,
    edit,
    editDisabled,
    currentUser,
    premiumFeatures,
    company,
    saveForm,
    departments,
    industries,
    specializations,
    isPublic,
    openModal,
    blockedCompany,
  } = props;
  const isEndClient = isEndClientFn(company);
  const isAffiliated = isAffiliatedFn(company);
  const isCurrent = company.id === companyID();
  const showPerformanceSupplier = shouldShowPerformance(company, 'supplier');
  const showPerformanceEmployer = shouldShowPerformance(company, 'employer');

  const verificationDocuments = map(company.verification_documents, (doc) => ({
    name: (
      <styled.CheckedItem>
        <FormattedMessage {...i18n[doc.type]} />
        <styled.Icon icon="Check" />
      </styled.CheckedItem>
    ),
  }));

  return (
    <styled.BottomRightSection>
      <styled.Section ratio="3/4" centerColumn>
        {blockedCompany && (
          <BlockWidget
            company={company}
            openModal={openModal}
            blockedCompany={blockedCompany}
          />
        )}
        {editDisabled && (
          <styled.InfoBox
            icon="WarningCircle"
            color="red"
            header={i18n.infoBoxHeader}
            content={i18n.infoBoxContent}
          />
        )}
        <About edit={edit} company={company} saveForm={saveForm} />

        <styled.NarrowInline hideOnLargeScreen>
          {!isEndClient && (
            <AssocList
              dataManual="profile.client-industries"
              context="ClientIndustries"
              edit={edit}
              company={company}
              items={industries}
              saveForm={saveForm}
            />
          )}

          {!isEndClient && (
            <AssocList
              dataManual="profile.roles"
              context="RoleSpecializations"
              edit={edit}
              company={company}
              items={specializations}
              saveForm={saveForm}
            />
          )}
        </styled.NarrowInline>

        {!isPublic &&
          !isCurrent &&
          showPerformanceSupplier &&
          company.performance && (
            <Performance
              company={company}
              definition={performanceTo}
              performance={company.performance.to}
            />
          )}
        {!isPublic &&
          !isCurrent &&
          showPerformanceEmployer &&
          company.performance && (
            <Performance
              company={company}
              definition={performanceFrom}
              performance={company.performance.from}
            />
          )}
        {!isPublic &&
          !isCurrent &&
          !showPerformanceEmployer &&
          !showPerformanceSupplier &&
          company.performance && (
            <Performance
              company={company}
              definition={performanceOverall}
              performance={company.performance.overall}
            />
          )}
        {isAffiliated && !isEmpty(company.verification_documents) && (
          <VerificationDocuments documents={company.verification_documents} />
        )}
        {!isPublic && !isCurrent && !premiumFeatures.company_profile_assoc && (
          <styledUI.Card bordered topBorder isSimple>
            <PremiumFeatureScene />
          </styledUI.Card>
        )}
        {premiumFeatures.company_profile_assoc && !isCurrent && (
          <Composer
            components={[
              <DocumentsProvider type="private" company={company} />,
            ]}
          >
            {([documentsProps]) => (
              <Documents
                {...documentsProps}
                intl={intl}
                currentUser={currentUser}
              />
            )}
          </Composer>
        )}
        {premiumFeatures.company_profile_assoc && !isCurrent && (
          <Composer components={[<NotesProvider company={company} />]}>
            {([notesProps]) => (
              <Notes {...notesProps} intl={intl} currentUser={currentUser} />
            )}
          </Composer>
        )}
      </styled.Section>
      <styled.Section ratio="1/4">
        {isEndClient && (
          <AssocList
            context="Industry"
            edit={edit}
            company={company}
            items={industries}
            saveForm={saveForm}
          />
        )}
        {!isEndClient && (
          <AssocList
            dataManual="profile.client-industries"
            context="ClientIndustries"
            hideOnNarrowScreen
            edit={edit}
            company={company}
            items={industries}
            saveForm={saveForm}
          />
        )}
        {isEndClient && (
          <AssocList
            dataManual="profile.departments"
            context="JobDepartments"
            edit={edit}
            company={company}
            items={departments}
            saveForm={saveForm}
          />
        )}
        {!isEndClient && (
          <AssocList
            dataManual="profile.roles"
            context="RoleSpecializations"
            hideOnNarrowScreen
            edit={edit}
            company={company}
            items={specializations}
            saveForm={saveForm}
          />
        )}
        <AssocList
          context="VerificationDocuments"
          isWideOnNarrowScreen
          input="documents"
          edit={false}
          company={{ documents: verificationDocuments }}
        />
      </styled.Section>
    </styled.BottomRightSection>
  );
}

AboutTabComponent.propTypes = {
  intl: PropTypes.object,
  edit: PropTypes.bool.isRequired,
  editDisabled: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  premiumFeatures: PropTypes.object,
  company: PropTypes.object.isRequired,
  saveForm: PropTypes.func.isRequired,
  isPublic: PropTypes.bool,
  departments: PropTypes.array,
  industries: PropTypes.array,
  specializations: PropTypes.array,
  blockedCompany: PropTypes.object,
};

export const AboutTab = injectIntl(AboutTabComponent);
