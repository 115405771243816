import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { WatchForRemoval } from 'react-mutation-observer';

import { Overlay } from 'components/structure/overlay/overlay';
import { SmartLink } from 'components/navigation/SmartLink';
import { Button } from 'components/elements/button';
import { Icon } from 'components/elements/icon';

import * as styled from './styles';
import i18n from './utils/i18n';

export class TrialExpired extends Component {
  componentDidCatch() {
    this.forceUpdate();
  }

  render() {
    const { userName } = this.props;

    return (
      <WatchForRemoval onRemoval={() => this.forceUpdate()}>
        <Overlay noClose>
          <styled.Wrapper>
            <styled.Header>
              <FormattedMessage {...i18n.title} />
            </styled.Header>

            <styled.Paragraph>
              <FormattedMessage {...i18n.subtitle} values={{ userName }} />
            </styled.Paragraph>

            <styled.List>
              <li>
                <Icon icon="Check" />
                <FormattedMessage {...i18n.check1} />
              </li>
              <li>
                <Icon icon="Check" />
                <FormattedMessage {...i18n.check2} />
              </li>
            </styled.List>

            <styled.ButtonWrapper>
              <SmartLink
                to="https://calendly.com/gustav-sales/gustav-become-a-customer"
                target="_blank"
              >
                <Button>
                  <FormattedMessage {...i18n.button} />
                </Button>
              </SmartLink>
            </styled.ButtonWrapper>
          </styled.Wrapper>
        </Overlay>
      </WatchForRemoval>
    );
  }
}

TrialExpired.propTypes = {
  userName: PropTypes.string,
};
