import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';

import { ImageCrop } from 'components/visual/imageCrop';
import { Modal } from 'components/overlay/Modal';
import * as styled from 'components/structure/form/styles/form';
import { returnOrNotifyUploadedFile } from 'components/utils/form-dropzone';
import type { FileUpload } from 'components/utils/form-dropzone';

import i18n from './utils/i18n';

/* eslint react/no-did-update-set-state: 0 */

type ImageUploadProps = {
  className?: string;
  image: string;
  initials?: boolean | string;
  isImageLoading?: boolean;
  shouldValidate?: boolean;
  formDefinition: {
    label?: string;
  };
  onUpload: (file: File) => void;
  icon?: {
    content?: React.ReactNode;
    dropZoneControlled?: boolean;
  };
  opacity?: number;
  color?: string;
  onCancel?: VoidCallback;
  onOpen?: VoidCallback;
  dropZoneProps?: object;
};

type ImageUploadState = {
  cropImage: boolean;
  imageToCrop: File | null;
  isImageLoading: boolean;
};

export class ImageUpload extends Component<ImageUploadProps, ImageUploadState> {
  constructor(props: ImageUploadProps) {
    super(props);

    this.state = {
      cropImage: false,
      imageToCrop: null,
      isImageLoading: props.isImageLoading!,
    };
  }

  componentDidUpdate(prevProps: ImageUploadProps) {
    const { isImageLoading: stateIsImageLoading } = this.state;
    const { isImageLoading: propIsImageLoading, image } = this.props;
    if (
      stateIsImageLoading &&
      ((prevProps.isImageLoading && !propIsImageLoading) ||
        image !== prevProps.image)
    ) {
      this.setState({ isImageLoading: false });
    }
  }

  cancelCrop = () => {
    const { onCancel = noop } = this.props;

    this.setState({
      cropImage: false,
      imageToCrop: null,
      isImageLoading: false,
    });

    onCancel();
  };

  croppedImage = (image: File) => {
    const { onUpload } = this.props;

    this.setState({
      cropImage: false,
      imageToCrop: null,
      isImageLoading: true,
    });

    onUpload(image);
  };

  shouldCrop = (image: File) => {
    const { onOpen = noop } = this.props;

    if (!image) {
      return;
    }

    this.setState({
      cropImage: true,
      imageToCrop: image,
    });

    onOpen();
  };

  updateImage = (images: FileUpload[]) => {
    const accepted = returnOrNotifyUploadedFile(images);

    if (accepted) {
      this.shouldCrop(accepted);
    }
  };

  renderCropper() {
    const { cropImage, imageToCrop } = this.state;

    if (!cropImage) {
      return null;
    }

    const ImgCropper = (
      <ImageCrop
        image={imageToCrop}
        onCrop={this.croppedImage}
        onCancel={this.cancelCrop}
      />
    );

    return (
      <Modal
        item={{
          type: 'custom',
          content: ImgCropper,
        }}
        onClose={this.cancelCrop}
        noCloseOnBackgroundClick
      />
    );
  }

  render() {
    const {
      className,
      shouldValidate,
      formDefinition,
      image,
      initials = false,
      icon,
      opacity,
      color,
      dropZoneProps,
    } = this.props;
    const { isImageLoading } = this.state;
    const subLabelEl = formDefinition.label ? (
      <styled.SubLabel>
        <FormattedMessage {...i18n.imageSizeLimit} />
      </styled.SubLabel>
    ) : null;

    return (
      <div className={className}>
        <styled.ImageFieldset
          context={formDefinition}
          picture={image}
          initials={initials}
          shouldValidate={shouldValidate}
          includeIndicator={false}
          isImageLoading={isImageLoading}
          fn={this.updateImage}
          icon={icon}
          opacity={opacity}
          color={color}
          dropZoneProps={dropZoneProps}
        >
          {subLabelEl}
        </styled.ImageFieldset>

        {this.renderCropper()}
      </div>
    );
  }
}
