import { get } from 'lodash';

export const jobsEnabled = (company) =>
  Boolean(get(company, 'subscription_plan.features.jobs', false));

export const sharedListsEnabled = (company) =>
  Boolean(get(company, 'subscription_plan.features.shared_lists', false));

export const collaborativeListsEnabled = (company) =>
  Boolean(
    get(company, 'subscription_plan.features.collaborative_lists', false)
  );

export const listsEnabled = (company) =>
  sharedListsEnabled(company) || collaborativeListsEnabled(company);

export const onlyJobsEnabled = (company) =>
  jobsEnabled(company) && !listsEnabled(company);

export const onlyListsEnabled = (company) =>
  listsEnabled(company) && !jobsEnabled(company);
