import { isEmpty } from 'lodash';

export const getMetaContent = (selector) => {
  const meta = document.querySelector(`meta[${selector}]`);
  const content = meta ? meta.getAttribute('content') : null;

  return isEmpty(content) ? null : content;
};

export const processHtmlContent = (html) => {
  if (!html) return '';

  let index = html.length - 1;

  while (index >= 0) {
    const char = html[index];

    if (char === '>') {
      return html;
    }
    if (char === '<') {
      return html.substring(0, index);
    }

    index -= 1;
  }

  return html;
};

export const removeWrapperTags = (content) =>
  content.replace(/^<p>/i, '').replace(/<\/p>$/i, '');
