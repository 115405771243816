import { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { Icon } from 'components/elements/icon';
import imageSrc from 'images/illustrations/gears.png';

import { EditModal as Modal } from './modal';
import { Form } from './form';

import i18n from '../utils/i18n';
import * as styled from '../styles/section';
import { FormDefinitionAbout } from '../utils/formDefinition';

export class About extends Component {
  constructor(props) {
    super(props);
    this.state = { modal: false };
  }

  handleSave = (company) => {
    const { saveForm } = this.props;

    saveForm(company).then(() => {
      this.toggleModal(false);
    });
  };

  isCompleted() {
    const { company } = this.props;

    return !isEmpty(company.about);
  }

  toggleModal(modal) {
    this.setState({ modal });
  }

  renderModal() {
    const { company } = this.props;

    return (
      <Modal
        title={i18n.modalTitleAbout}
        size="large"
        onClose={() => this.toggleModal(false)}
      >
        <Form
          editObject={company}
          saveForm={this.handleSave}
          formDefinition={FormDefinitionAbout}
        />
      </Modal>
    );
  }

  render() {
    const { company, edit } = this.props;
    const { modal } = this.state;

    if (!edit && !this.isCompleted()) {
      return null;
    }

    return (
      <>
        <styled.Card bordered isSimple>
          <styled.Section
            title={<FormattedMessage {...i18n.titleAbout} />}
            data-manual="profile.about"
          >
            {edit && (
              <styled.ButtonEdit
                color={this.isCompleted() ? 'primary' : 'danger'}
                onClick={() => this.toggleModal(true)}
                type="blank"
                size="large"
              >
                <Icon icon="NotePencil" />
              </styled.ButtonEdit>
            )}
            <styled.Text>{company.about}</styled.Text>
            {!this.isCompleted() && (
              <styled.EmptyState>
                <styled.Image src={imageSrc} />
                {edit ? (
                  <styled.ButtonEditEmpty
                    onClick={() => this.toggleModal(true)}
                  >
                    <FormattedMessage {...i18n.buttonEmptyAbout} />
                  </styled.ButtonEditEmpty>
                ) : (
                  <styled.Message isMissing>
                    <FormattedMessage {...i18n.noAboutText} />
                  </styled.Message>
                )}
              </styled.EmptyState>
            )}
          </styled.Section>
        </styled.Card>

        {edit && modal && this.renderModal()}
      </>
    );
  }
}

About.propTypes = {
  edit: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired,
  saveForm: PropTypes.func.isRequired,
};
