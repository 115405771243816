import { FormattedMessage } from 'react-intl';
import { compact, startsWith, isEmpty, some } from 'lodash';

import { isPrideStaff } from 'utils/checkCompany';
import { IntercomAPI } from 'components/scripts/intercom';
import { FeatureVersionBadge } from 'components/misc/Badge';
import i18n from 'scenes/utils/i18n';
import {
  jobsEnabled as getJobsEnabled,
  listsEnabled as getListsEnabled,
  onlyListsEnabled,
} from 'components/utils/subscriptionPlans';

export const sidebarMenuAdmin = ({ company_type: companyType }) =>
  compact([
    {
      label: <FormattedMessage {...i18n.adminCompanies} />,
      path: '/admin/companies',
      slug: 'adminCompanies',
      icon: 'Users',
    },
    {
      label: <FormattedMessage {...i18n.adminIntegrations} />,
      path: '/admin/integrations',
      slug: 'adminIntegrations',
      icon: 'Gear',
    },
    companyType === 'administrator' && {
      label: <FormattedMessage {...i18n.adminMessages} />,
      path: '/admin/messages',
      slug: 'adminMessages',
      icon: 'Chat',
    },
    {
      label: <FormattedMessage {...i18n.adminSubscriptionPlans} />,
      path: '/admin/subscription-plans',
      slug: 'adminSubscriptionPlans',
      icon: 'Shield',
    },
    companyType === 'administrator' && {
      label: <FormattedMessage {...i18n.adminHotlist} />,
      path: '/admin/hotlist',
      slug: 'adminHotlist',
      icon: 'Fire',
    },
    {
      label: <FormattedMessage {...i18n.adminDataAnaylsis} />,
      path: '/admin/data-analysis',
      slug: 'adminDataAnalysis',
      icon: 'Stack',
    },
  ]);

const sidebarMenu = (company) => {
  const {
    id: companyId,
    company_type: companyType,
    features: companyFeatures,
    show_looker_iframe: lookerEnabled,
  } = company;
  const isEmployer =
    companyType === 'employer' || companyFeatures.employer === true;
  const jobsEnabled = getJobsEnabled(company);
  const listsEnabled = getListsEnabled(company);

  const isActiveFn = (paths, excluded) => (match, { pathname }) => {
    const somePathMatches = some(paths, (path) => startsWith(pathname, path));
    const noneExcludedPathsMatch =
      isEmpty(excluded) || !some(excluded, (path) => pathname === path);

    return somePathMatches && noneExcludedPathsMatch;
  };

  return compact([
    jobsEnabled && {
      label: <FormattedMessage {...i18n.hotlist} />,
      path: '/hotlist',
      icon: 'Fire',
      slug: 'hotlist',
      counter: { key: 'hotlist.total_unread' },
      subMenu: {
        title: <FormattedMessage {...i18n.hotlist} />,
        badge: <FeatureVersionBadge version="beta" />,
        slug: 'hotlist',
        bottomSection: 'hotlistLimit',
        items: [
          {
            label: i18n.hotlistFind,
            path: '/hotlist/find',
            slug: 'find',
            isActive: isActiveFn([
              '/hotlist/find',
              '/hotlist/saved',
              '/hotlist/requested',
            ]),
            isOpen: true,
            items: [
              {
                label: i18n.hotlistDiscover,
                slug: 'find',
                path: '/hotlist/find',
                icon: 'Fire',
              },
              {
                label: i18n.hotlistRequested,
                slug: 'requested',
                path: '/hotlist/requested',
                icon: 'EnvelopeSimple',
                counter: {
                  key: 'hotlist.requested',
                  highlight: 'hotlist.requested_unread',
                },
              },
              {
                label: i18n.hotlistSaved,
                slug: 'saved',
                path: '/hotlist/saved',
                icon: 'Heart',
                counter: { key: 'hotlist.saved' },
              },
              {
                label: i18n.hotlistArchived,
                slug: 'archived',
                path: '/hotlist/archived',
                icon: 'Book',
                counter: { key: 'hotlist.archived' },
              },
            ],
          },
          {
            label: i18n.hotlistCandidates,
            path: '/hotlist/active',
            slug: 'candidates',
            isActive: isActiveFn([
              '/hotlist/active',
              '/hotlist/draft',
              '/hotlist/ended',
            ]),
            isOpen: true,
            items: [
              {
                label: i18n.hotlistActive,
                slug: 'active',
                path: '/hotlist/active',
                icon: 'PlayCircle',
                counter: {
                  key: 'hotlist.active',
                  highlight: 'hotlist.active_unread',
                },
                preservedQueryParameters: ['filters.scope'],
              },
              {
                label: i18n.hotlistDraft,
                slug: 'draft',
                path: '/hotlist/draft',
                icon: 'PencilLine',
                counter: { key: 'hotlist.draft' },
                preservedQueryParameters: ['filters.scope'],
              },
              {
                label: i18n.hotlistEnded,
                slug: 'ended',
                path: '/hotlist/ended',
                icon: 'XCircle',
                counter: { key: 'hotlist.ended' },
                preservedQueryParameters: ['filters.scope'],
              },
            ],
          },
        ],
      },
    },
    listsEnabled && {
      label: <FormattedMessage {...i18n.sharedLists} />,
      path: '/shared-lists?sub-sidebar=expanded',
      icon: 'UserList',
      slug: 'shared_lists',
      counter: { key: 'shared_lists.total_unread' },
      isActive: (match, { pathname }) =>
        match || startsWith(pathname, '/shared-lists'),
    },
    {
      label: <FormattedMessage {...i18n.messages} />,
      path: '/messages',
      icon: 'ChatCircle',
      counter: { key: 'shared_lists.messages_unread' },
      slug: 'all-messages',
    },
    !isPrideStaff(companyId) && {
      label: <FormattedMessage {...i18n.candidates} />,
      path: '/candidates',
      icon: 'Users',
      slug: 'candidates',
    },
    {
      label: <FormattedMessage {...i18n.clients} />,
      path: '/clients',
      icon: 'Suitcase',
      slug: 'clients',
    },
    jobsEnabled &&
      isEmployer &&
      lookerEnabled && {
        label: <FormattedMessage {...i18n.reporting} />,
        path: '/reporting',
        icon: 'TrendUp',
        slug: 'reporting',
        subMenu: {
          title: <FormattedMessage {...i18n.reporting} />,
          items: [
            {
              label: i18n.reportingCandidatesReceived,
              slug: 'reporting-candidates',
              path: '/reporting/candidates-received',
              items: [
                {
                  label: i18n.reportingCandidatesOverview,
                  slug: 'reporting-candidates-overview',
                  path: '/reporting/candidates-received/overview',
                  icon: 'Users',
                },
                {
                  label: i18n.reportingCandidatesStages,
                  slug: 'reporting-candidates-stages',
                  path: '/reporting/candidates-received/stages',
                  icon: 'Funnel',
                },
              ],
            },
            {
              label: i18n.reportingJobsShared,
              slug: 'reporting-jobs',
              path: '/reporting/jobs-shared',
            },
          ],
        },
      },
    jobsEnabled &&
      isEmployer &&
      !lookerEnabled && {
        label: <FormattedMessage {...i18n.reporting} />,
        path: '/reporting',
        icon: 'TrendUp',
        slug: 'reporting',
      },
  ]);
};

export const buildSidebarMenu = (company) => {
  if (
    company.company_type === 'administrator' ||
    company.company_type === 'fuse_admin'
  ) {
    return sidebarMenuAdmin(company);
  }

  return sidebarMenu(company);
};

export const sidebarMenuProfile = (company) => {
  const companyType = company.company_type;
  const showCompanyProfile =
    companyType !== 'administrator' &&
    companyType !== 'fuse_admin' &&
    !onlyListsEnabled(company);

  const helpCenterUrl = () => {
    if (onlyListsEnabled(company)) {
      return '//help.candidate.ly/';
    }
    return '//help.hellogustav.com/en/';
  };

  return compact([
    showCompanyProfile && {
      label: <FormattedMessage {...i18n.companyProfile} />,
      path: '/company/profile',
      icon: 'Briefcase',
    },
    companyType !== 'administrator' &&
      companyType !== 'fuse_admin' && {
        label: <FormattedMessage {...i18n.settings} />,
        path: '/settings/company',
        icon: 'Gear',
      },
    companyType !== 'administrator' &&
      companyType !== 'fuse_admin' && {
        label: <FormattedMessage {...i18n.yourAccount} />,
        path: '/account',
        icon: 'User',
      },
    companyType !== 'administrator' &&
      companyType !== 'fuse_admin' && {
        label: <FormattedMessage {...i18n.subscription} />,
        path: '/subscription-plan',
        icon: 'Shield',
        border: 'bottom',
      },
    {
      label: <FormattedMessage {...i18n.support} />,
      onAction: () => IntercomAPI('show'),
    },
    {
      label: <FormattedMessage {...i18n.helpCenter} />,
      onAction: () => {
        window.open(helpCenterUrl(), '_blank');
      },
      border: 'bottom',
    },
    {
      label: <FormattedMessage {...i18n.logout} />,
      path: '/logout',
    },
  ]);
};
