/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import PropTypes from 'prop-types';

import { createRef, PureComponent } from 'react';
import setupLogRocketReact from 'logrocket-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { push } from 'redux-first-history';
import { createStructuredSelector } from 'reselect';

import { isEmpty, get } from 'lodash';
import moment from 'moment';
import LogRocket from 'logrocket';
import { Datacenter, datadogRum } from '@datadog/browser-rum';

import { LoadingBar } from 'components/feedback/LoadingBar';
import { Sidebar } from 'containers/Sidebar';
import { Analytics } from 'containers/Analytics';

import { Actions, fetchCounters } from 'containers/App/actions/sessions';
import { closeModal } from 'containers/App/actions/ui';

import { IntegrationsProvider } from 'containers/Integrations/Provider';
import { CompanyDomainsProvider } from 'containers/CompanyDomains/Provider';
import { AdminHeader } from 'containers/AdminHeader';
import { Loading } from 'components/feedback/loading/loading';
import {
  Intercom,
  Provider as IntercomProvider,
} from 'components/scripts/intercom';
import { Upscope } from 'components/scripts/upscope';
import { CookieScript } from 'components/scripts/cookieScript';
import { Segment, SegmentAPI } from 'components/scripts/segment';
import { Delighted } from 'components/scripts/delighted';
import { Firebase } from 'components/scripts/firebase';
import { TrackJs, TrackJsAPI } from 'components/scripts/trackjs';
import { Modal } from 'components/overlay/Modal';
import { ReleaseUpdateComponent as ReleaseUpdateModal } from 'containers/ReleaseUpdate';
import { TrialExpired as TrialExpiredModal } from 'containers/TrialExpired';
import {
  AppContainer,
  AppBody,
} from 'components/structure/page/styles/containers';
import { settingsReducer } from 'containers/Settings/reducer';
import { accountReducer } from 'containers/YourAccount/Account/reducer';

import { injectSaga } from 'utils/injectSaga';
import { injectReducer } from 'utils/injectReducer';
import { isAdministrator } from 'utils/authorization/utils/auth';
import { isProduction } from 'utils/node-env-vars';
import {
  getServerEnv,
  isProductionServer,
  isIntegrationIframe,
  isAlternativePortalDomain,
} from 'utils/checkEnvironment';
import { commitRef, isDevCommit } from 'utils/git-vars';
import { setTagsContextForErrorReporting } from 'utils/errorLogging';
import { SidebarProvider } from 'components/structure/SidebarContext';
import { onlyListsEnabled } from 'components/utils/subscriptionPlans';
import { removeSensitiveData } from 'components/utils/object';

import { rootSaga as saga } from './sagas';
import { Config } from './config';
import {
  uiSelector,
  userSelector,
  companySelector,
  releaseSelector,
  isAuthenticatedSelector,
  userChannelSelector,
} from './selectors';
import {
  isPublicPath,
  isUnauthorizedPath,
  isOnboarding,
  isSettingPassword,
  isConfirmEmail,
  isLoggingOut,
  isLoggingInToken,
  isWithSidebarPath,
  isWithSubMenuPath,
  isSharedListsAllowedPath,
  isPublicPortalPath,
  isCustomDomainPage,
} from './utils/helpers';
import { sessionExpired, handleSessionExpired } from './utils/expirationTimer';
import { BrowserBanner } from './scenes/browserBanner';
import * as styled from './scenes/styles';

class AppComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.appContainer = createRef();
    this.state = {
      sidebarExpanded: false,
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('phoenixAuthToken');
    const adminMembershipId = localStorage.getItem('adminMembershipId');
    const { dispatch, location } = this.props;

    if (token && !isLoggingOut(location) && !isSettingPassword(location)) {
      if (sessionExpired()) {
        if (!isLoggingInToken(location)) {
          handleSessionExpired(dispatch);
        }
      } else {
        dispatch(Actions.currentSession());
      }
    }

    if (adminMembershipId && !isPublicPath(location)) {
      dispatch({
        type: 'SET_ADMIN_MEMBERSHIP_ID',
        adminMembershipId,
      });
    }

    setTagsContextForErrorReporting();

    if (isProduction()) {
      LogRocket.init(Config.LogRocketAppId, {
        console: {
          isEnabled: {
            log: false,
            info: false,
            debug: false,
          },
        },
        network: {
          requestSanitizer: (request) => {
            if (!isEmpty(request.body)) {
              request.body = removeSensitiveData(request.body);
            }
            if (!isEmpty(request.headers)) {
              request.headers = removeSensitiveData(request.headers);
            }
            return request;
          },
          responseSanitizer: (response) => {
            if (!isEmpty(response.body)) {
              response.body = removeSensitiveData(response.body);
            }
            if (!isEmpty(response.headers)) {
              response.headers = removeSensitiveData(response.headers);
            }
            return response;
          },
        },
      });
      setupLogRocketReact(LogRocket);

      LogRocket.getSessionURL((sessionURL) => {
        SegmentAPI('track', 'LogRocket', { sessionURL });
        TrackJsAPI('addMetadata', 'LogRocket', sessionURL);
      });

      datadogRum.init({
        applicationId: Config.DataDog.applicationId,
        clientToken: Config.DataDog.clientToken,
        datacenter: Datacenter.US,
        resourceSampleRate: 100,
        sampleRate: 100,
        trackInteractions: true,
        env: getServerEnv(window),
      });
    }

    this.handleRedirects();

    const { history } = this.props;
    history.listen(this.reloadAppIfNewRelease);
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      history,
      channel,
      handleFetchCounters,
      company,
      user,
      isAuthenticated,
    } = this.props;

    if (company && !prevProps.company) {
      handleFetchCounters();
    }

    if (user && !prevProps.user) {
      this.handleUpdateTimezone();
    }

    if (!prevProps.channel && channel) {
      this.unlisten = history.listen((loc) => {
        if (isAuthenticated && !isUnauthorizedPath(loc)) {
          dispatch(Actions.keepUserAlive(channel));
        }
      });
    }

    if (!channel && prevProps.channel && this.unlisten) {
      this.unlisten();
    }

    this.handleRedirects();
  }

  handleRedirects() {
    const { user, company, location, handleRedirect } = this.props;

    if (isSettingPassword(location) || isLoggingOut(location)) {
      return;
    }
    if (!isPublicPortalPath(location)) {
      if (isCustomDomainPage()) {
        handleRedirect('/');
        return;
      }
      if (isAlternativePortalDomain()) {
        window.location.replace('https://candidate.ly');
        return;
      }
      if (get(user, 'is_simple')) {
        handleRedirect('/public/vendor-hub');
        return;
      }
    }
    if (get(user, 'needs_confirm_email')) {
      if (!isConfirmEmail(location) && !isLoggingInToken(location)) {
        handleRedirect('/confirm-email');
      }
      return;
    }

    if (
      isOnboarding(location) &&
      !get(user, 'needs_onboarding', true) &&
      !get(company, 'needs_onboarding', true)
    ) {
      handleRedirect('/');
      return;
    }

    if (get(user, 'needs_onboarding') || get(company, 'needs_onboarding')) {
      if (!isOnboarding(location)) {
        handleRedirect('/onboarding');
      }
      return;
    }

    if (onlyListsEnabled(company)) {
      if (!isSharedListsAllowedPath(location)) {
        handleRedirect('/shared-lists');
      }
      return;
    }
    if (get(company, 'subscription_plan_pending')) {
      if (
        !location.pathname.startsWith('/subscription-plan') &&
        !isOnboarding(location)
      ) {
        handleRedirect('/subscription-plan/setup');
      }
    }
  }

  handleUpdateTimezone() {
    const { user, handleUpdateUser } = this.props;
    const adminMembershipId = localStorage.getItem('adminMembershipId');

    if (window.Intl && !adminMembershipId) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (!isEmpty(timezone) && user.timezone !== timezone) {
        handleUpdateUser({ timezone });
      }
    }
  }

  reloadAppIfNewRelease = () => {
    const { release } = this.props;

    if (
      !isEmpty(release) &&
      !isDevCommit() &&
      commitRef() !== release.release_info.rev
    ) {
      window.location.reload();
    }
  };

  toggleSidebar = (value) => this.setState({ sidebarExpanded: value });

  renderModals() {
    const { ui, dispatch } = this.props;

    if (isEmpty(ui.modals)) {
      return null;
    }

    const modals = ui.modals.map((item) => (
      <Modal
        item={item}
        key={`modal-${item.type}`}
        onClose={(modal) => dispatch(closeModal(modal))}
      />
    ));
    return <div>{modals}</div>;
  }

  renderPageLoading() {
    const { ui } = this.props;
    if (ui.pageLoading) {
      return <Loading />;
    }
    return null;
  }

  renderUpdateModal() {
    // The information about release returned from backend will look like this
    // {"release_info":{"rev":"0c56f2fa"}}
    const { release } = this.props;

    if (
      !isEmpty(release) &&
      !isDevCommit() &&
      commitRef() !== release.release_info.rev
    ) {
      return <ReleaseUpdateModal />;
    }

    return null;
  }

  renderTrialModal() {
    const { company, user } = this.props;
    const trialEndsAt = get(company, 'trial_ends_at');
    const userName = get(user, 'first_name');

    if (
      trialEndsAt &&
      Math.ceil(moment(trialEndsAt).diff(moment(), 'd', true)) <= 0
    ) {
      return <TrialExpiredModal userName={userName} />;
    }

    return null;
  }

  renderSidebar() {
    const { history } = this.props;
    const { sidebarExpanded } = this.state;

    return (
      <Sidebar
        history={history}
        sidebarExpanded={sidebarExpanded}
        toggleSidebar={this.toggleSidebar}
      />
    );
  }

  render() {
    const { ui, location, isAuthenticated, route } = this.props;
    const { sidebarExpanded } = this.state;

    const hasSidebar =
      isAuthenticated &&
      isWithSidebarPath(location) &&
      !isIntegrationIframe(window);

    return (
      <IntercomProvider>
        <IntegrationsProvider>
          <CompanyDomainsProvider>
            <SidebarProvider value={{ expanded: sidebarExpanded }}>
              <LoadingBar />
              <AppContainer ref={this.appContainer}>
                {hasSidebar && this.renderSidebar()}
                <AppBody
                  hasSidebar={hasSidebar}
                  hasSubMenu={hasSidebar && isWithSubMenuPath(location)}
                  sidebarExpanded={sidebarExpanded}
                >
                  {!isIntegrationIframe(window) && (
                    <>
                      {!isPublicPath(location) && <AdminHeader />}
                      <BrowserBanner />
                    </>
                  )}

                  {this.renderUpdateModal()}
                  {this.renderTrialModal()}

                  {renderRoutes(route.routes)}
                </AppBody>
                {isProduction() && <TrackJs token={Config.TrackJsToken} />}
                {isProduction() && (
                  <CookieScript
                    apiKey={Config.CookieScriptKey}
                    isAuthenticated={isAuthenticated}
                    location={location}
                  />
                )}
                {isProductionServer(window) && (
                  <Upscope apiKey={Config.UpscopeApiKey} />
                )}
                {isProductionServer(window) && !isAdministrator() && (
                  <Delighted apiKey={Config.DelightedApiKey} />
                )}
                {isProduction() &&
                  !isAdministrator() &&
                  !isPublicPath(location) && (
                    <Segment
                      apiKey={
                        Config.SegmentApiKey[getServerEnv(window) || 'dev']
                      }
                    />
                  )}
                {(isProductionServer(window) ||
                  isCustomDomainPage() ||
                  isAlternativePortalDomain()) &&
                  !isAdministrator() &&
                  !isPublicPath(location) && (
                    <Intercom
                      appContainer={this.appContainer.current}
                      scrollUp={ui.sideModal}
                    />
                  )}
                {isAuthenticated && isProduction() && (
                  <Firebase
                    config={Config.FirebaseConfig}
                    vapidKey={Config.FirebaseVapidKey}
                  />
                )}
                {!isAdministrator() && <Analytics />}
              </AppContainer>
              <styled.FlashMessage
                position="bottom-left"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
              />
              {this.renderModals()}
              {this.renderPageLoading()}
            </SidebarProvider>
          </CompanyDomainsProvider>
        </IntegrationsProvider>
      </IntercomProvider>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  ui: uiSelector,
  user: userSelector,
  company: companySelector,
  release: releaseSelector,
  isAuthenticated: isAuthenticatedSelector,
  channel: userChannelSelector,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  handleFetchCounters: () => dispatch(fetchCounters()),
  handleUpdateUser: (params) => dispatch(Actions.updateUserSettings(params)),
  handleRedirect: (path) => dispatch(push(path)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: '/', saga });
const withSettingsReducer = injectReducer({
  key: 'Settings',
  reducer: settingsReducer,
});
const withAccountReducer = injectReducer({
  key: 'Account',
  reducer: accountReducer,
});

AppComponent.propTypes = {
  route: PropTypes.object,
  ui: PropTypes.object,
  user: PropTypes.object,
  company: PropTypes.object,
  channel: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  dispatch: PropTypes.func,
  release: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  handleFetchCounters: PropTypes.func,
  handleUpdateUser: PropTypes.func,
  handleRedirect: PropTypes.func,
};

export const App = compose(
  withSaga,
  withConnect,
  withSettingsReducer,
  withAccountReducer
)(AppComponent);
