export const PUBLIC_PATHS = [
  '/login',
  '/itserve',
  '/404',
  '/401',
  '/forgotpassword',
  '/signup',
  '/logout',

  '/public/vendor-hub',
  '/public/company/profile/\\w*',
  '/public/uuid-converter',

  '/sl/\\w*',

  '/cp/\\w*',
  '/cp/\\w*/requests',
  '/cp/\\w*/requests/\\w*',
  '/cp/\\w*/\\w*',
  '/cp/\\w*/\\w*/forward-list',
  '/cp/\\w*/\\w*/subscribe',
  '/cp/\\w*/\\w*/candidate/\\w*',
  '/cp/\\w*/\\w*/candidate/\\w*/change-stage',
];

// TOP ADMIN LEVEL FOR ALL COMPANIES:
const commonAuthorizedPaths = [
  '/',
  '/setpassword',
  '/resetpassword',
  '/confirm-email',
  '/onboarding',
  '/onboarding/(welcome|set-password|create-company|about-yourself|personal-profile|company-profile|finished)',
];

// SHARED FEATURES FOR ALL COMPANY TYPES:
const commonFeaturesAuthorizedPaths = [
  '/account',
  '/account/user-profile',
  '/account/connected-accounts',
  '/connect_account/bullhorn',

  '/company/profile',
  '/company/profile/about',
  '/company/profile/users',
  '/company/profile/\\w*',
  '/company/profile/\\w*/about',
  '/company/profile/\\w*/users',

  '/user/profile',
  '/user/profile/\\w*',

  '/candidates',
  '/candidates/\\w*',
  '/candidates/\\w*/addto',
  '/candidates/\\w*/addto/job',
  '/candidates/\\w*/addto/circle',
  '/candidates/circles',
  '/candidates/circles/new',
  '/candidates/circles/\\w*',
  '/candidates/circles/\\w*/\\w*',
  '/candidates/circles/\\w*/edit',
  '/candidates/circles/\\w*/\\w*/addto',
  '/candidates/circles/\\w*/\\w*/addto/job',
  '/candidates/circles/\\w*/\\w*/addto/circle',

  '/work-orders',
  '/work-orders/pending',
  '/work-orders/upcoming',
  '/work-orders/active',
  '/work-orders/declined',
  '/work-orders/archived',
  '/work-orders/\\w+',

  '/hotlist',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/add',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/edit/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/activate/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/duplicate/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/candidate/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/request/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/complete-profile',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/alerts',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/alerts/add',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/alerts/edit/\\w*',

  '/reporting',
  '/reporting/candidates-received',
  '/reporting/candidates-received/overview',
  '/reporting/candidates-received/stages',

  '/settings',
  '/settings/company',
  '/settings/teams',
  '/settings/teams/new',
  '/settings/teams/\\w*/edit',
  '/settings/teams/\\w*/users',
  '/settings/members',
  '/settings/members/invite',
  '/settings/members/\\w*/remove',
  '/settings/members/\\w*/teams',
  '/settings/company-profile',
  '/settings/company-profile/about',
  '/settings/company-profile/users',
  '/settings/general',
  '/settings/shared-lists/\\w*',
  '/settings/shared-lists/themes',
  '/settings/shared-lists/themes/\\w*',
  '/settings/shared-lists/appearances',
  '/settings/shared-lists/appearances/new',
  '/settings/shared-lists/appearances/\\w*',
  '/settings/shared-lists/appearances/\\w*/(edit|duplicate)',
  '/settings/shared-lists/custom-fields',
  '/settings/shared-lists/custom-fields/new',
  '/settings/shared-lists/custom-fields/\\w*/edit',
  '/settings/shared-lists/client-portal',
  '/settings/shared-lists/email-templates',
  '/settings/shared-lists/email-templates/\\w*',
  '/settings/shared-lists/candidate-statuses',
  '/settings/shared-lists/notifications',
  '/settings/shared-lists/pdf-export',

  '/subscription-plan',
  '/subscription-plan/setup',

  '/shared-lists',
  '/shared-lists/new/(job|marketing|connect)',
  '/shared-lists/\\w*',
  '/shared-lists/\\w*/(candidates|config|activity|messages)',
  '/shared-lists/\\w*/activity/schedule',
  '/shared-lists/\\w*/(candidates|messages)/\\w*',
  '/shared-lists/\\w*/messages/\\w*/threads/\\w*',
  '/shared-lists/\\w*/(candidates|messages)/\\w*/(edit|activate|review|vms-submit)',
  '/shared-lists/\\w*/(candidates|messages)/\\w*/request/\\w*',
  '/shared-lists/\\w*/(candidates|messages)/\\w*/(request|share)/\\w*/(edit|activate|review|vms-submit)',
  '/shared-lists/\\w*/(candidates|messages)/\\w*/share/\\w*',
  '/shared-lists/\\w*/(candidates|messages)/\\w*/(request|share)/\\w*/(duplicate-candidate|remove-cv|delete-candidate|replace-cv|upload-cv|change-stage|reveal-cv|request-cv)',

  '/shared-lists/\\w*/(candidates|messages)/\\w*/(duplicate-candidate|remove-cv|delete-candidate|replace-cv|upload-cv|reveal-cv|request-cv|change-stage)',
  '/shared-lists/\\w*/(candidates|messages)/request/\\w*',
  '/shared-lists/\\w*/(candidates|messages)/(activate|review)/\\w*',
  '/shared-lists/\\w*/(candidates|messages)/(edit|add|activate|review|vms-submit)/\\w*',

  '/shared-lists/\\w*/candidates/access-requests/\\w*/approve',
  '/shared-lists/\\w*/candidates/submission-modal/\\w*',
  '/shared-lists/\\w*/(candidates|messages)/request/\\w*/edit/\\w*',
  '/shared-lists/\\w*/config/general',
  '/shared-lists/\\w*/config/appearance',
  '/shared-lists/\\w*/config/candidate-statuses',
  '/shared-lists/\\w*/config/automations',
  '/shared-lists/\\w*/config/automations/new',
  '/shared-lists/\\w*/config/automations/\\w*',
  '/shared-lists/\\w*/config/automations/\\w*/edit',
  '/shared-lists/\\w*/config/notifications',
  '/shared-lists/\\w*/config/data-control',

  '/clients',
  '/clients/new',
  '/clients/find',
  '/clients/find/contacts',
  '/clients/\\w*',
  '/clients/\\w*/lists',
  '/clients/\\w*/contacts',
  '/clients/\\w*/contacts/new',
  '/clients/\\w*/contacts/\\w*/edit',
  '/clients/\\w*/contact/\\w*',
  '/clients/\\w*/contact/\\w*/edit',
  '/clients/\\w*/contact/\\w*/(lists|owners)',
  '/clients/\\w*/contact/\\w*/(lists|owners)/edit',

  '/messages',
  '/messages/\\w*',

  '/candidates',
  '/candidates/\\w*',
];

const administratorAuthorizedPaths = [
  '/',
  '/admin',

  '/admin/companies',
  '/admin/companies/list',
  '/admin/companies/users',
  '/admin/companies/invites',
  '/admin/companies/list/merge',

  '/admin/company/\\w*',
  '/admin/company/\\w*/users',
  '/admin/company/\\w*/settings',
  '/admin/company/\\w*/domains',
  '/admin/company/\\w*/domains/new',
  '/admin/company/\\w*/domains/\\w*/edit',

  '/admin/integrations',

  '/admin/messages',
  '/admin/messages/failed',
  '/admin/messages/processed',

  '/admin/subscription-plans',
  '/admin/subscription-plans/new',
  '/admin/subscription-plans/logs',
  '/admin/subscription-plans/\\w*',
  '/admin/subscription-plans/\\w*/edit',
  '/admin/subscription-plans/\\w*/companies',
  '/admin/subscription-plans/\\w*/companies/add',

  '/admin/hotlist',
  '/admin/hotlist/\\w*/match',
  '/admin/hotlist/\\w*/ui',
  '/admin/hotlist/\\w*/reindex',

  '/admin/data-analysis',
];

const employerAuthorizedPaths = [
  // TODO: these are redirects from old paths; remove when not needed anymore
  '/ats/\\w*',
  '/ats/\\w*/overview',
  '/ats/\\w*/applicants',
  '/ats/\\w*/questions',
  '/ats/\\w*/supply-candidate',
  '/ats/\\w*/supply-candidate/resupply',
  '/ats/\\w*/supply-candidate/cv',
  '/ats/\\w*/supply-candidate/form',
  '/ats/\\w*/supply-candidate/\\w*/submit',

  '/ats/\\w*/applicants/profile/\\w*',
  '/ats/\\w*/applicants/profile/\\w*/score',
  '/ats/\\w*/applicants/profile/\\w*/description',
  '/ats/\\w*/applicants/profile/\\w*/messenger',
  '/ats/\\w*/applicants/profile/\\w*/activity',
  '/ats/\\w*/applicants/profile/\\w*/documents',
  '/ats/\\w*/applicants/profile/\\w*/contact',
  '/ats/\\w*/applicants/profile/\\w*/skills',

  '/settings/integrations',
  '/settings/integrations/\\w*',
  '/settings/integrations/\\w*/shared-lists',

  '/account/notifications',
  '/account/user-profile',

  '/integrations/bullhorn',
  '/integrations/bullhorn/shared-lists',
  '/integrations/bullhorn/clients',
  '/integrations/bullhorn/candidates',

  '/work-orders/new/\\w+',
];

const supplierAuthorizedPaths = [
  // TODO: these are redirects from old paths; remove when not needed anymore
  '/ats/\\w*',
  '/ats/\\w*/overview',
  '/ats/\\w*/applicants',
  '/ats/\\w*/questions',
  '/ats/\\w*/applicants/profile/\\w*/messenger',

  '/settings/documents',

  '/account/notifications',
  '/account/user-profile',
];

export const getAuthorizedPaths = (companyType, companyFeatures) => {
  if (companyType === 'administrator' || companyType === 'fuse_admin') {
    return [...commonAuthorizedPaths, ...administratorAuthorizedPaths];
  }

  const canSeeEmployerPaths =
    companyType === 'employer' || companyFeatures.employer === true;
  const canSeeSupplierPaths =
    companyType === 'supplier' || companyFeatures.supplier === true;

  return [
    ...commonAuthorizedPaths,
    ...commonFeaturesAuthorizedPaths,
    ...(canSeeEmployerPaths ? employerAuthorizedPaths : []),
    ...(canSeeSupplierPaths ? supplierAuthorizedPaths : []),
  ];
};
