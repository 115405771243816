/* eslint-disable react/no-danger */
import { useEffect, useRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import { Icon } from 'components/elements/icon';
import { processHtmlContent } from 'components/utils/html';

import * as styled from '../styles/suggestions';
import i18n from '../utils/i18n';
import type { PromptSuggestion } from '../..';

type SuggestionsProps = {
  inProgress: boolean;
  isReplacing: boolean;
  suggestions: Suggestion[];
  promptSuggestions: PromptSuggestion[];
  currentIndex: number;
  setIndex: (value: number) => void;
  onGenerate: (value?: Prompt) => void;
  onReplaceContent: (value: Suggestion) => void;
};

export const Suggestions = (props: SuggestionsProps) => {
  const {
    inProgress,
    isReplacing,
    suggestions,
    promptSuggestions,
    currentIndex,
    setIndex,
    onReplaceContent,
    onGenerate,
  } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  const previousSuggestion = () => setIndex(Math.max(currentIndex - 1, 0));

  const nextSuggestion = () =>
    setIndex(Math.min(currentIndex + 1, suggestions.length - 1));

  const currentSuggestion = get(suggestions, currentIndex);
  const selectedContent = currentSuggestion.content?.modified_selected_part;
  const followUp = currentSuggestion?.followup_prompt;
  const isLast = currentIndex === suggestions.length - 1;

  useEffect(() => {
    setIndex(suggestions.length - 1);
  }, [suggestions.length]);

  useEffect(() => {
    if (isLast && isReplacing && currentSuggestion?.is_complete) {
      onReplaceContent(currentSuggestion);
    }
  }, [isReplacing, currentSuggestion, isLast]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [contentRef.current?.scrollHeight]);

  const processedContent = useMemo(() => processHtmlContent(selectedContent), [
    selectedContent,
  ]);

  const currentSuggestionFollowupPrompt = get(
    currentSuggestion,
    'followup_prompt'
  );
  const matchingDefaultSuggestion = promptSuggestions.find(
    ({ shortValue }) => shortValue === currentSuggestionFollowupPrompt
  );

  return (
    <styled.Wrapper>
      <styled.Header>
        <styled.HeaderItem>
          <styled.NavigationButton
            icon="CaretLeft"
            disabled={currentIndex === 0}
            onClick={previousSuggestion}
          />
          <styled.Count>
            {`${currentIndex + 1} / ${suggestions.length}`}
          </styled.Count>
          <styled.NavigationButton
            icon="CaretRight"
            disabled={isLast}
            onClick={nextSuggestion}
          />
        </styled.HeaderItem>

        {isLast && (
          <styled.RegenerateButton
            type="blank-underlined"
            color="white"
            onClick={() => onGenerate()}
          >
            <styled.ButtonText>
              <FormattedMessage {...i18n.regenerate} />
            </styled.ButtonText>
          </styled.RegenerateButton>
        )}

        <styled.ReplaceButton
          color="grape"
          onClick={() => onReplaceContent(currentSuggestion)}
          disabled={inProgress}
          loading={isLast && isReplacing && !currentSuggestion.is_complete}
        >
          <Icon icon="Swap" />
          <styled.ButtonText>
            <FormattedMessage {...i18n.replace} />
          </styled.ButtonText>
        </styled.ReplaceButton>
      </styled.Header>
      <styled.Content ref={contentRef}>
        {followUp && (
          <styled.FollowUpPrompt>
            {matchingDefaultSuggestion?.emojiName && (
              <styled.Emoji name={matchingDefaultSuggestion.emojiName} />
            )}
            {followUp}
          </styled.FollowUpPrompt>
        )}
        <styled.SelectedContent text={processedContent} />
      </styled.Content>
    </styled.Wrapper>
  );
};
